import raf from '../helpers/raf';
import scrollTo from '../helpers/scrollTo';
import { mq } from '../helpers/css.json';
import watcher from './../helpers/watcher';

class Nav{
  get height(){
    return this.el.clientHeight;
  }

  constructor( el ){
    this.el = el;

    this.toggle = this.toggle.bind( this );
    this.toggleDisclaimer = this.toggleDisclaimer.bind( this );
    this.animateButton = this.animateButton.bind( this );
    this.handleScreenTransition = this.handleScreenTransition.bind( this );
    this.handleAnchors = this.handleAnchors.bind( this );
    this.toggleFixed = this.toggleFixed.bind( this );
    this.home = document.body.classList.contains( 'home' );

    this.els = {
      menuToggle: this.el.querySelector( '.nav-toggle' ),
      menu: this.el.querySelector( '.nav-menu' ),
      disclaimerButton: this.el.querySelector( '.wood-panel' ),
      disclaimerClose: document.body.querySelector( '.disclaimer-close' ),
      anchors: Array.from( this.el.querySelectorAll( '.nav-links a' )),
      screenTitle: Array.from( this.el.querySelectorAll( '.current-screen' ))
    };

    this.data = {
      top: this.el.offsetTop
    };

    this.mq = window.matchMedia( mq.w1024 );

    this.mq.addListener( this.handleBreakpoint.bind( this ));

    if( this.home ){
      watcher.listen( 'scroll', this.toggleFixed );
    }

    this.el.addEventListener( 'expandtoggle', this.toggle );
    this.el.addEventListener( 'transitionend', this.handleScreenTransition );

    if( this.home ){
      this.el.addEventListener( 'click', this.handleAnchors );
    }

    if (this.els.disclaimerButton) {
      this.els.disclaimerButton.addEventListener('click', this.toggleDisclaimer);
      this.els.disclaimerButton.addEventListener('animationend', this.animateButton);
    }

    if( this.els.disclaimerClose ){
      this.els.disclaimerClose.addEventListener( 'click', this.toggleDisclaimer );
    }

    this.handleBreakpoint();
  }

  animateButton(){
    this.els.disclaimerButton.classList.remove( 'play' );

    this.animateTimeout = window.setTimeout(() => {
      this.els.disclaimerButton.classList.add( 'play' );
    }, ( Math.random() + 2 ) * 1000 );
  }

  currentScreen( title, titleIndex ){
    if( this.currentTitle === title || this.changing || document.documentElement.classList.contains( 'fixed-nav' )){
      return;
    }

    this.changing = true;

    // const navIndex = titleIndex - 1;
    const currentNavIndex = this.currentTitleIndex - 1;

    // if( this.els.anchors[ navIndex ]){
    //   this.els.anchors[ navIndex ].setAttribute( 'aria-current', 'true' );
    // }

    if( this.els.anchors[ currentNavIndex ]){
      this.els.anchors[ currentNavIndex ].removeAttribute( 'aria-current' );
    }

    if( this.mq.matches ){
      this.changing = false;
    }

    this.el.classList.toggle( 'previous', titleIndex < this.currentTitleIndex );

    const currentTitle = this.els.screenTitle[ 0 ];
    let nextTitle;

    if( title ){
      nextTitle = document.createElement( 'p' );

      nextTitle.classList.add( 'current-screen' );
      nextTitle.classList.add( 'start' );
      nextTitle.innerHTML = title;

      this.el.appendChild( nextTitle );

      this.els.screenTitle.push( nextTitle );
    }

    raf.request(() => {
      if( currentTitle ){
        currentTitle.classList.remove( 'in' );
        currentTitle.classList.add( 'out' );
      }
      if( nextTitle ){
        nextTitle.classList.add( 'in' );
        nextTitle.classList.remove( 'start' );
      }
    });

    this.currentTitle = title;
    this.currentTitleIndex = titleIndex;

    if( !nextTitle && !currentTitle ){
      this.changing = false;
    }
  }

  handleAnchors( event ){

    const link = event.target.closest( '.nav-links a[href^="/#"]' );

    if( !link ){
      return;
    }

    event.preventDefault();

    const id = link.getAttribute( 'href' ).replace( '#', '' ).replace( '/', '' );
    const linkedEl = document.getElementById( id );

    if( !linkedEl ){
      return;
    }

    if( this.mq.matches || link.classList.contains( 'nav-home' )){

      this.currentScroll = linkedEl.offsetTop - this.height;
      scrollTo( linkedEl.offsetTop - this.height, 500 );
      raf.request(() => {
        document.querySelector( '#menu [aria-expanded="true"]' ).click();
      });
    }
    else{
      this.currentScroll = linkedEl.offsetTop - document.querySelector( '.nav[role="navigation"]' ).offsetHeight;
      document.documentElement.scrollTop = document.body.scrollTop = this.currentScroll;
      raf.request(() => {
        this.els.menuToggle.click();
      });
    }

  }

  handleBreakpoint(){
    if( this.mq.matches ){
      this.els.menu.removeAttribute( 'aria-hidden' );
      document.documentElement.classList.remove( 'fixed-nav' );

      return;
    }

    this.els.menu.setAttribute( 'aria-hidden', true );
  }

  handleScreenTransition( event ){
    if( event.target.nodeName === 'svg' || !event.target.classList.contains( 'current-screen' )){
      return;
    }

    this.changing = false;

    if( !event.target.classList.contains( 'out' )){
      return;
    }

    event.target.parentElement.removeChild( event.target );

    this.els.screenTitle.shift();

  }

  stopButton(){
    window.clearTimeout( this.animateTimeout );
    this.els.disclaimerButton.classList.remove( 'play' );
  }

  toggle( event ){
    const block = event.target;
    const { next, hide } = event.detail;
    const height = block.clientHeight;

    const done = function( event ){
      if( event.target !== block ){
        return;
      }

      block.removeEventListener( 'transitionend', done );
      block.removeAttribute( 'style' );

      // prevent scroll to top on close
      // if( !this.mq.matches ){

      //   raf.request(() => {
      //     document.documentElement.classList.toggle( 'fixed-nav', !hide );
      //     document.documentElement.scrollTop = document.body.scrollTop = this.currentScroll;
      //   });
      // }

      next( block, hide );
    }.bind( this );

    block.addEventListener( 'transitionend', done );

    if( hide ){
      block.style.overflow = 'hidden';
      block.style.height = `${height}px`;

      raf.request(() => {
        block.style.height = '1px';
      });

      return;
    }

    block.style.height = '1px';
    block.style.overflow = 'hidden';

    const show = function(){
      raf.request(() => {
        block.style.height = `${height}px`;
      });
    };

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const offsetTop = this.el.offsetTop;

    this.currentScroll = offsetTop;

    if( offsetTop > scrollTop ){
      scrollTo( offsetTop, 500 );
      show();
      return;
    }

    show();
  }

  toggleDisclaimer(){
    const disclaimer = document.getElementById( this.els.disclaimerButton.getAttribute( 'aria-controls' ));
    const hide = disclaimer.getAttribute( 'aria-hidden' ) !== 'true';

    this.els.disclaimerButton.classList.toggle( 'hide', !hide );

    disclaimer.style.display = 'flex';

    raf.request(() => {
      disclaimer.classList.toggle( 'hide', hide );
    });

    if( !hide ){
      disclaimer.setAttribute( 'aria-hidden', hide );
      this.els.disclaimerClose.focus();
      this.stopButton();
    }
    else {
      this.els.disclaimerButton.focus();
      this.animateButton();
    }

    const done = event => {
      if( event.target !== disclaimer ){
        return;
      }

      disclaimer.removeEventListener( 'transitionend', done );

      disclaimer.setAttribute( 'aria-hidden', hide );

      disclaimer.removeAttribute( 'style' );
    };

    disclaimer.addEventListener( 'transitionend', done );

  }
  toggleFixed( scroll ){
    this.el.classList.toggle( 'sticky', scroll > this.data.top );
  }
}

export default ( function(){
  const nav = document.querySelector( '.nav[role="navigation"]' );

  if( nav ){
    return new Nav( nav );
  }
})();
