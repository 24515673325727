import { mq } from '../helpers/css.json';

const focusables = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex], [contentEditable]';

const CLOSE_SELECTOR = '.expand-close';
const TOGGLE_SELECTOR = '.expand-toggle[aria-controls]';


class ExpandToggle{
  constructor(){
    this._handleToggle = this._handleToggle.bind( this );
    this._handleKey = this._handleKey.bind( this );
    this._handleClose = this._handleClose.bind( this );

    this._expands = [];
    this.mq = window.matchMedia( mq.w1024 );

    document.body.addEventListener( 'click', this._handleClose );
    document.body.addEventListener( 'click', this._handleToggle );
    document.body.addEventListener( 'keydown', this._handleKey );
  }

  get blocks(){
    return this._expands.slice();
  }

  _clean( block, hide ){
    block.classList.remove( 'toggle' );

    if( hide ){
      if( block.contains( document.activeElement )){
        block.activeOpener.focus();
      }

      return;
    }

    let interactiveElement = block.querySelector( focusables );

    if( !interactiveElement ){
      block.setAttribute( 'tabindex', '-1' );

      interactiveElement = block;
    }

    interactiveElement.focus();
  }

  _handleClose( event ){
    const button = event.target.closest( CLOSE_SELECTOR );

    if( !button ){
      return;
    }

    const block = button.closest( '[aria-hidden]' );

    if( !block ){
      return;
    }

    this.toggle( block, true );
  }

  _handleKey( event ){
    if( event.keyCode !== 27 ){
      return;
    }

    const currentBlock = this._expands[ this._expands.length - 1 ];

    if( !currentBlock ){
      return;
    }

    this.toggle( currentBlock );
  }

  _handleToggle( event ){
    const button = event.target.closest( TOGGLE_SELECTOR );

    if( !button ){
      return;
    }

    const block = document.getElementById( button.getAttribute( 'aria-controls' ));

    if( !block ){
      return;
    }

    block.activeOpener = button;

    const active = document.querySelector( '.nav-menu[id^="megamenu"][aria-hidden="false"]' );

    if( active ){
      if( active != block ){
        this.toggle( document.querySelector( '.nav-menu[id^="megamenu"][aria-hidden="false"]' ));
      }
      // close main menu on close submenu
      // if( !this.mq.matches ){
      //   this.toggle( document.querySelector( '.nav-menu[aria-hidden="false"]' ));
      // }
    }
    this.toggle( block );
  }

  closeAll( keep ){
    this._expands.forEach( expand => {
      if( expand === keep ){
        return;
      }

      this.toggle( expand, true );
    });
  }

  toggle( block, hide ){
    hide = hide !== undefined ? hide : block.getAttribute( 'aria-hidden' ) !== 'true';

    if( !block.openers ){
      block.openers = Array.from( document.querySelectorAll( `[aria-controls="${block.id}"]` ));
    }

    if( hide ){
      document.documentElement.classList.remove( 'no-scroll' );
    }
    else{
      document.documentElement.classList.add( 'no-scroll' );
    }

    block.classList.add( 'toggle' );

    block.openers.forEach( opener => {
      opener.setAttribute( 'aria-expanded', !hide );
    });

    block.setAttribute( 'aria-hidden', hide );

    this.trigger( block, hide );
    this._updateStore( block, hide );
  }

  trigger( block, hide ){
    const params = {
      bubbles: true,
      cancelable: false,
      detail: {
        hide,
        next: () => {
          this._clean( block, hide );
        }
      }
    };

    const event = new CustomEvent( 'expandtoggle', params );

    block.dispatchEvent( event );
  }

  _updateStore( block, hide ){
    if( !hide ){
      this._expands.push( block );

      return;
    }

    const blockIndex = this._expands.indexOf( block );

    this._expands.splice( blockIndex, 1 );
  }
}

export default new ExpandToggle();
