import Screen from './screen';
import raf from '../helpers/raf';
import Animations from './story-animations';
import Parallax from 'parallax-js';

class Story extends Screen{

  get offsetTop(){
    return this.el.offsetTop;
  }

  constructor( el ){
    super( el );

    this.handleTransition = this.handleTransition.bind( this );
    this.restart = this.restart.bind( this );

    this.els = {
      steps: Array.from( this.el.querySelectorAll( '.story-wrapper' )),
      restart: document.querySelector( '.story-restart' )
    };
    this.parallax = [];

    this.currentStep = 0;
    // this.currentStep = this.els.steps.indexOf( this.els.steps.find( el => el.classList.contains( 'active' ))) || 0;

    this.el.addEventListener( 'transitionend', this.handleTransition );
    this.els.restart.addEventListener( 'click', this.restart );

    this.animations = new Animations( this.els.steps );

    this.els.steps.forEach( step => {
      const block = step.querySelector( '.parallax' );

      if( !block ){
        return false;
      }

      const parallax = new Parallax( step.querySelector( '.parallax' ));

      parallax.disable();
      this.parallax.push( parallax );
    });

    if( this.parallax[ this.currentStep ]){
      this.parallax[ this.currentStep ].enable();
    }

    this.ready();

  }

  active(){
    // this.el.classList.remove( 'inactive' );

    if( !this.animations || this.playing ){
      return;
    }

    this.playing = true;

    raf.request(() => {
      this.els.steps[ this.currentStep ].classList.add( 'play' );

    });

    if( !this.firstPlay ){
      this.animations.play( this.currentStep );
      this.firstPlay = true;
    }
    else{
      this.restartTimeout = window.setTimeout(() => {
        this.play();
      }, 2000 );
    }
  }

  handleTransition( event ){
    if( this.els.steps.indexOf( event.target ) > -1 ){
      if( event.target.classList.contains( 'active' )){
        this.nextStepActive();
      }

      return;
    }

    if( this.playing && event.propertyName === 'opacity' && event.target.closest( '.story-text p:last-of-type span:last-of-type' ) === event.target ){
      this.restartTimeout = window.setTimeout(() => {
        this.play();
      }, 2000 );
    }

  }

  inactive(){
  // inactive( ratio, screenRatio ){
  //   if( screenRatio < 2 ){
  //     this.el.classList.remove( 'inactive' );
  //
  //     return;
  //   }

    window.clearTimeout( this.restartTimeout );

    if( this.playing ){
      this.animations.stop( this.currentStep );
      this.playing = false;
    }

    // this.el.classList.add( 'inactive' );
  }

  nextStepActive(){
    let currentStep = this.els.steps[ this.currentStep ];

    if( !currentStep ){
      currentStep = this.els.steps[ this.els.steps.length - 1 ];
    }

    currentStep.classList.remove( 'active' );
    currentStep.classList.remove( 'play' );

    this.animations.stop( this.currentStep );

    this.currentStep++;

    this.els.steps[ this.currentStep ].classList.remove( 'fade' );
  }

  play( nextIndex = this.currentStep + 1 ){
    const nextStep = this.els.steps[ nextIndex ];

    // loop
    if( !nextStep ){
      this.reset();

      return;
    }

    if( this.parallax[ nextIndex ]){
      this.parallax[ nextIndex ].enable();
    }

    nextStep.classList.add( 'fade' );

    raf.request(() => {
      nextStep.classList.add( 'active' );
      this.animations.play( nextIndex );

      raf.request(() => {
        nextStep.classList.add( 'play' );
      });
    });
  }

  reset(){
    this.currentStep = -1;

    window.setTimeout(() => {
      this.play();
    }, 2750 );
  }

  restart(){
    if( this.currentStep === 0 ){
      return;
    }

    this.currentSlide = this.els.steps[ this.currentStep ];

    this.currentStep = -1;
    this.playing = true;

    this.play();

    this.currentSlide.classList.remove( 'active' );
    this.currentSlide.classList.remove( 'play' );

    this.playing = false;
  }
}

export default Story;
