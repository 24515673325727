import lottie from '../helpers/lottie';
import raf from '../helpers/raf';

const animations = {
  'story1': [ 'plane', 'squirrel' ],
  'story2': [ 'rocket' ],
  'story3': [ 'wand', 'aligator' ],
  'story4': [ 'monkey' ],
  'story5': [ 'waterBack', 'submarine', 'waterFront' ]
};

class Animations{
  constructor( stories ){
    this.stories = stories;
    this.lottie = {};
  }

  create( storyName, animations ){

    this.lottie[ storyName ] = {};

    animations.forEach( name => {
      this.lottie[ storyName ][ name ] = lottie( name );
    });
  }

  play( index ){
    const storyName = `story${index + 1}`;

    if( !this[ storyName ]){
      return;
    }

    if( !this.lottie[ storyName ]){
      this.create( storyName, animations[ storyName ]);
    }

    this[ storyName ]( true );
  }

  stop( index ){

    const storyName = `story${index + 1}`;

    if( !this[ storyName ]){
      return;
    }

    this[ storyName ]( false );
  }

  story1( play ){
    const lotties = this.lottie.story1;

    if( !play ){
      lotties.squirrel.stop();
      lotties.plane.stop();
      window.clearTimeout( lotties.squirrel.timeout );

      document.getElementById( 'anim-plane' ).classList.remove( 'play' );

      return;
    }

    lotties.squirrel.loop = false;
    lotties.squirrel.addEventListener( 'complete', () => {
      lotties.squirrel.timeout = window.setTimeout(() => {
        lotties.squirrel.goToAndPlay( 0 );
      }, 2000 );
    });

    lotties.squirrel.play();

    raf.request(() => {
      document.getElementById( 'anim-plane' ).classList.add( 'play' );
    });

    lotties.plane.play();
  }

  story2( play ){
    const lotties = this.lottie.story2;

    if( !play ){
      lotties.rocket.stop();

      return;
    }

    lotties.rocket.play();
  }

  story3( play ){
    const lotties = this.lottie.story3;

    if( !play ){
      lotties.aligator.stop();
      lotties.wand.stop();

      window.clearTimeout( lotties.aligator.timeout );
      window.clearTimeout( lotties.wand.timeout );

      return;
    }

    lotties.aligator.loop = false;
    lotties.aligator.addEventListener( 'complete', () => {
      lotties.aligator.timeout = window.setTimeout(() => {
        lotties.aligator.goToAndPlay( 0 );
      }, 2000 );
    });

    lotties.wand.loop = false;
    lotties.wand.timeout = window.setTimeout(() => {
      lotties.wand.goToAndPlay( 0 );
    }, 1800 );

    lotties.aligator.play();
  }

  story4( play ){
    const lotties = this.lottie.story4;

    if( !play ){
      lotties.monkey.stop();

      document.getElementById( 'anim-monkey' ).classList.remove( 'play' );

      return;
    }

    raf.request(() => {
      document.getElementById( 'anim-monkey' ).classList.add( 'play' );
    });

    lotties.monkey.play();
  }

  story5( play ){
    const lotties = this.lottie.story5;

    if( !play ){
      lotties.waterBack.stop();
      lotties.waterFront.stop();
      lotties.submarine.stop();

      return;
    }

    lotties.waterBack.play();
    lotties.waterFront.play();
    lotties.submarine.play();
  }

}


export default Animations;
