const iOS = [ 'iPhone', 'iPad' ];

class SidePadding{
  constructor(){
    this.params = {
      rootClass: 'overflow-padding',
      selectors: [ 'body' ]
    };

    this._iOS = iOS.indexOf( navigator.platform ) > -1;

    this.styleBlock = document.createElement( 'style' );
    this.styleBlock.id = 'sidePadding';

    document.head.appendChild( this.styleBlock );

    this._throttleResize();
  }

  get scrollbarWidth(){
    if( !this._outer ){
      this._outer = document.createElement( 'div' );
      this._outer.style.visibility = 'hidden';
      this._outer.style.width = '100px';
    }

    if( !this._inner ){
      this._inner = document.createElement( 'div' );
      this._inner.style.width = '100%';
    }

    document.body.appendChild( this._outer );

    const widthNoScroll = this._outer.offsetWidth;

    this._outer.style.overflow = 'scroll';

    this._outer.appendChild( this._inner );

    const widthWithScroll = this._inner.offsetWidth;

    this._outer.style.overflow = '';

    // remove divs
    this._inner.parentElement.removeChild( this._inner );
    this._outer.parentElement.removeChild( this._outer );

    return widthNoScroll - widthWithScroll;
  }

  _apply(){
    this.width = this.scrollbarWidth;


    const declaration = `
    .${this.params.rootClass},
    .${this.params.rootClass} body {
      overflow: hidden;
    }

    ${ this._iOS ?
    `.${this.params.rootClass}{
      height: 100vh;
    }

    .${this.params.rootClass} body {
      height: 100vh;
      position: fixed;
      width: 100%;
      z-index: 0;
    }` : ''}

    ${this.selector}{
      padding-right: ${this.width}px;
    }`;

    this.styleBlock.innerHTML = declaration;
  }

  _throttleResize(){
    let running = false;
    const func = () => {
      if( running ){
        return;
      }

      running = true;

      window.requestAnimationFrame(() => {
        this._apply();
        running = false;
      });
    };

    window.addEventListener( 'resize', func );
  }

  set(){
    this.scroll = document.documentElement.scrollTop || document.body.scrollTop;

    document.documentElement.classList.add( this.params.rootClass );

    document.documentElement.scrollTop = document.body.scrollTop = this.scroll;
  }

  update( params = {}){
    Object.assign( this.params, params );

    this.selector = this.params.selectors.map( selector => {
      return `.${this.params.rootClass} ${selector}`;
    }).join();

    this._apply();
  }

  unset(){
    document.documentElement.classList.remove( this.params.rootClass );

    document.documentElement.scrollTop = document.body.scrollTop = this.scroll;
  }
}

export default new SidePadding();
