import Overlay from '@accede-web/overlay';
import raf from '../helpers/raf';
import sidePadding from '../helpers/side-padding';

class ApplicationOverlay{
  constructor(){
    this.checkHash = this.checkHash.bind( this );
    this.handleTransition = this.handleTransition.bind( this );

    this.overlayContent = document.getElementById( 'overlay-application' );

    window.addEventListener( 'hashchange', this.checkHash );

    this.checkHash();
  }

  checkHash(){
    const hash = window.location.hash.replace( '#', '' );

    if( hash !== 'applications' ){
      return;
    }

    if( !this.overlay ){
      this.createOverlay();
    }

    sidePadding.set();

    this.overlay.show();

    this.overlay.el.style.display = 'flex';

    raf.request(() => {
      this.overlay.el.classList.remove( 'closed' );
    });
  }

  createOverlay(){
    this.overlay = new Overlay({
      content: this.overlayContent,
      className: 'overlay-wrapper closed',
      closeSelector: '.overlay-close'
    });

    this.overlayContent.removeAttribute( 'aria-hidden' );
    this.overlay.el.addEventListener( 'transitionend', this.handleTransition );

    this.overlay.addEventListener( 'close', () => {
      this.overlay.el.style.display = 'flex';
      this.overlay.el.classList.add( 'closed' );
      window.location.hash = '';
    });
  }

  handleTransition( e ){
    if( e.target !== this.overlayContent || e.propertyName.indexOf( 'transform' ) == -1 ){
      return;
    }

    if( this.overlay.el.classList.contains( 'closed' )){
      sidePadding.unset();
    }

    this.overlay.el.removeAttribute( 'style' );
  }
}

export default new ApplicationOverlay();
