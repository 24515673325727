const listeners = [ 'size', 'scroll' ];

class Watcher{
  constructor(){
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    this.tick = this.tick.bind( this );

    this.callback = {
      size: [],
      scroll: []
    };

    this.data = {
      scroll: this.scrollTop,
      size: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    };

    window.requestAnimationFrame( this.tick );
  }

  handleSize(){
    const { innerHeight, innerWidth } = window;
    const widthChanged = this.data.size.width !== innerWidth;
    const heightChanged = this.data.size.height !== innerHeight;

    const newSize = {};

    if( widthChanged ){
      newSize.width = innerWidth;
    }

    if( heightChanged ){
      newSize.height = innerHeight;
    }

    if( widthChanged || heightChanged ){
      this.callback.size.forEach( callback => {
        callback( Object.assign({}, newSize ));
      });
    }

    Object.assign( this.data.size, newSize );
  }

  handleScroll(){
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if( scrollTop !== this.scrollTop ){
      this.scrollTop = scrollTop;

      this.callback.scroll.forEach( callback => {
        callback( scrollTop );
      });
    }
  }

  listen( type, callback ){
    if( listeners.indexOf( type ) === -1 ){
      return;
    }

    this.callback[ type ].push( callback );

    if( type === 'size' ){
      callback( Object.assign({}, this.data.size ));
    }
    else{
      callback( this.data[ type ]);
    }
  }

  stop( type, callback ){
    if( listeners.indexOf( type ) === -1 ){
      return;
    }

    const callbackIndex = this.callback[ type ].indexOf( callback );

    if( callbackIndex === -1 ){
      return;
    }

    this.callback[ type ].splice( callbackIndex, 1 );
  }

  tick(){
    this.handleScroll();
    this.handleSize();

    window.requestAnimationFrame( this.tick );
  }
}

export default new Watcher();
