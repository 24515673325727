// polyfils
import './polyfills/classList.js';
import './polyfills/customEvent.js';
import './polyfills/closest.js';
import './polyfills/object-assign.js';
import './polyfills/array-from.js';

// Helpers
import './helpers/expand-toggle';

import KB from '@switch-company/keyboard-navigation';
import sidePadding from './helpers/side-padding';

new KB();

sidePadding.update({
  selectors: [
    '[class^="screen-"]',
    'nav.nav'
  ]
});

// Libs
// import './modules/cookie';
import './modules/applications';
// import './modules/splashscreen';
// import './modules/nav';

import './modules/story';
import Story from "./modules/story";
// import './modules/city';
// import './modules/post-it';
// import './modules/eat-well';

if( typeof CSS == 'undefined' || ( CSS && !CSS.supports ) || ( CSS.supports && !CSS.supports( 'object-fit', 'cover' ))){
  [].slice.call( document.querySelectorAll( '.ofit' )).forEach(( elt ) => {

    const parent = elt.parentNode.tagName.toLowerCase() !== 'picture' ? elt.parentNode : elt.parentNode.parentNode;

    parent.classList.add( 'object-fit' );
    parent.style.backgroundImage = `url(${elt.src})`;

  });
}

$(document).ready(function() {

  // Accordions
  $('.js-has-accordions-list').each(function () {
    $(this).find('.accordion--trigger').on('click', function () {
      $(this).closest('li').siblings('li').find('.accordion--trigger').removeClass('is-expanded').siblings('.accordion--dropdown').stop().slideUp('fast');
      $(this).toggleClass('is-expanded').siblings('.accordion--dropdown').stop().slideToggle('fast');
    });
  });

  $('.js-has-accordions-home').each(function () {
    $(this).find('.js-accordion--trigger').on('click', function () {
      $(this).closest('li').siblings('li').find('.js-accordion--trigger').removeClass('is-expanded').siblings('.js-accordion--dropdown').stop().slideUp('fast');
      $(this).toggleClass('is-expanded').siblings('.js-accordion--dropdown').stop().slideToggle('fast');
    });
  });

  // SLIDERS
  $('.slider').flickity({
    prevNextButtons: false,
    groupCells: true,
    // if set to true,
    // group cells that fit in carousel viewport
    dragThreshold: 10,
    // dragging doesn't start until 10px moved
    // more room for vertical scrolling
    // on touch devices
  });

  // const screen = document.getElementById('story');
  // new Story( screen );
});
