import watcher from '../helpers/watcher';
import nav from './nav';

// let nav = document.querySelector( '.nav[role="navigation"]' );

const screens = [];

class Screen{
  static get screens(){
    return screens;
  }

  get offsetTop(){
    return this.el.offsetTop - this._navHeight;
  }

  get _navHeight(){
    return nav.height;
  }

  get title(){
    return this._title;
  }

  constructor( el ){
    screens.push( this );

    this.el = el;

    const title = this.el.querySelector( '.screen-title' );

    this._title = title ? title.innerHTML : undefined;

    this._position = {
      before: true,
      in: false,
      after: false
    };

    this.scrollTop = 0;

    this.handlePosition = this.handlePosition.bind( this );
  }

  ready(){
    watcher.listen( 'scroll', this.handlePosition );
  }

  active(){
    // console.log( this.el.id, 'active', ratio );
  }

  inactive(){
    // console.log( this.el.id, 'inactive', ratio );
  }

  handlePosition( scrollTop ){
    // console.log( this.el, 'in', scroll );
    const height = this.el.clientHeight;
    const viewportHeight = window.innerHeight;
    const top = this.offsetTop;
    const direction = scrollTop > this.scrollTop ? 'down' : 'up';

    this.scrollTop = scrollTop;

    let screenRatio = ( scrollTop + viewportHeight - top ) / viewportHeight;
    let elRatio = ( scrollTop - top ) / height;

    // if(( screenRatio < 0 && this._position.before ) || ( elRatio > 1 && this._position.after )){
    //   return;
    // }


    if( screenRatio <= 0 ){
      this._position = {
        before: true,
        in: false,
        after: false
      };

      screenRatio = 0;
    }
    else if( elRatio >= 1 ){
      this._position = {
        before: false,
        in: false,
        after: true
      };

      elRatio = 1;
    }
    else {
      this._position = {
        before: false,
        in: true,
        after: false
      };
    }

    if( this._position.in ){
      this.active( screenRatio, elRatio );

      if( direction === 'up' && elRatio < 0 && elRatio > -1 && screenRatio < 1 ){
        const previousScreenIndex = Screen.screens.indexOf( this ) - 1;
        const previousScreen = Screen.screens[ previousScreenIndex ];

        if( !previousScreen ){
          return;
        }

        nav.currentScreen( previousScreen.title, previousScreenIndex );

        return;
      }

      if( screenRatio >= 0.5 && screenRatio <= 1 ){
        nav.currentScreen( this.title, Screen.screens.indexOf( this ));

        return;
      }

      if( this.el.id === 'city' && screenRatio < 0.5 ){
        nav.currentScreen( null, 0 );

        return;
      }
    }
    else {
      this.inactive( elRatio, screenRatio );
    }


  }
}

export default Screen;
