function request( callback ){
  return window.requestAnimationFrame(() => {
    window.requestAnimationFrame( callback );
  });
}

function cancel( raf ){
  window.cancelAnimationFrame( raf );
  window.cancelAnimationFrame( raf + 1 );
}

export default { request, cancel };
