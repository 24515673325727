export default function( animationId, parameters ){
  const data = window.animations[ animationId ];

  if( !data ){
    return;
  }

  const params = Object.assign({
    animationData: data,
    loop: true,
    autoplay: false,
    name: animationId,
    renderer: 'svg',
    container: document.getElementById( `anim-${animationId}` )
  }, parameters );

  return window.bodymovin.loadAnimation( params );
}
