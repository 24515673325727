const data = {
  animate: {}
};

// easing function
const easeInOutQuad = function( t ) {
  return t < 0.5 ? 2 * t * t : -1 + ( 4 - 2 * t ) * t;
};

/**
 * Handle scroll animation
 * @method
 * @param {number} currentTimestamp Timestamp in milliseconds
**/
function animate( currentTimestamp ){

  // stop if we don't control the scroll
  // ( user scrolled )
  if( !data.controlledScroll ){
    // reset the animation's values
    data.animate = {};

    return;
  }

  // store the animation's start timestamp
  data.animate.timestamp = data.animate.timestamp || currentTimestamp;

  // get the duration since the start timestamp
  var duration = currentTimestamp - data.animate.timestamp;

  // test if we need to start the animation
  if( duration > data.animate.scrollDuration ){
    const offset = data.animate.targetOffset;

    // apply the targeted offset
    document.documentElement.scrollTop = document.body.scrollTop = data.animate.targetOffset;
    // reset the animation's values
    data.animate = {};

    data.controlledScroll = false;

    // scroll callback
    if( data.callback ){
      data.callback( offset );
    }

    return;
  }

  // get the eased duration
  const eased = easeInOutQuad((( duration ) / data.animate.scrollDuration ) % 1 );
  // get the scroll position based on the eased duration
  const scroll = Math.ceil( data.animate.distance * eased ) + data.animate.currentScrollTop;

  // apply the scroll position
  document.documentElement.scrollTop = document.body.scrollTop = scroll;

  // request another tick
  data.animate.raf = window.requestAnimationFrame( animate );
}

/**
 * Animate the scroll to the selected section
 * @method
 * @param {integer} sectionIndex - the selected section's index
**/
function scrollTo( position, duration = 700, cb ){
  if( data.controlledScroll ){
    return;
  }

  if( !duration ){
    document.documentElement.scrollTop = document.body.scrollTop = position;

    return;
  }

  data.animate.currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  data.animate.targetOffset = position;
  data.animate.distance = data.animate.targetOffset - data.animate.currentScrollTop;
  data.animate.scrollDuration = duration;
  data.controlledScroll = true;

  data.callback = cb;

  data.animate.raf = window.requestAnimationFrame( animate );
}

export default scrollTo;
